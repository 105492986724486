<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit Audience
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Update your {{ type }} audience.
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column mt-3">
        <v-radio-group v-model="share_privacy" @change="onChange">
          <v-radio
            :value="option.title"
            v-for="option in shareOptions"
            :key="option.key"
          >
            <template v-slot:label>
              <div
                class="d-flex align-center hover-item pa-2 w-100 rounded-lg"
                :class="{
                  'selected-item-color': share_privacy == option.title,
                }"
              >
                <div
                  style="background: lightgray; padding: 16px; border-radius: 50px;"
                >
                  <v-icon size="35">{{ option.icon }}</v-icon>
                </div>
                <div class="app-bold-font font-18 ml-5">{{ option.title }}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: String,
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      message: null,
      loading: false,
      profile: {},
      share_privacy: "",
      shareOptions: [
        { title: "Only me", icon: "mdi-lock" },
        {
          title: "Only agencies I fanned",
          icon: "mdi-domain",
        },
        {
          title: "Only followed volunteers",
          icon: "mdi-account-multiple",
        },
        {
          title: "All followed agencies and volunteers",
          icon: "mdi-account-group",
        },
        {
          title: "Public",
          icon: "mdi-earth",
        },
      ],
    };
  },
  methods: {
    ...mapActions("auth", {
      updateProfile: "updateProfile",
    }),
    onChange() {
      this.message = null;
      console.log(this.share_privacy);
    },
    onSave() {
      this.loading = true;
      let params = {};
      params[`${this.type}_share_privacy`] = this.share_privacy;

      this.updateProfile(params)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
  },
  computed: {
    ...mapState("auth", {
      myProfile: "profile",
    }),
  },
  watch: {
    myProfile(newValue) {
      this.share_privacy = newValue[`${this.type}_share_privacy`];
      if (!this.share_privacy) this.share_privacy = "Only me";
    },
  },
  mounted() {
    this.share_privacy = this.myProfile[`${this.type}_share_privacy`];
    if (!this.share_privacy) this.share_privacy = "Only me";
  },
};
</script>
<style scoped>
.selected {
  background: #eef3f8;
}
</style>
